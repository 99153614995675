import { useEffect, useState } from 'react';

type DeviceType = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export const useDeviceDetect = (): DeviceType => {
  const [deviceType, setDeviceType] = useState<DeviceType>({
    isMobile: false,
    isTablet: false,
    isDesktop: false
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const checkDeviceType = () => {
      const mobileMatch = window.matchMedia('(max-width: 767px)');
      const tabletMatch = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
      
      setDeviceType({
        isMobile: mobileMatch.matches,
        isTablet: tabletMatch.matches,
        isDesktop: !mobileMatch.matches && !tabletMatch.matches
      });
    };

    // 初始检测
    checkDeviceType();

    // 添加响应式监听
    const mobileQuery = window.matchMedia('(max-width: 767px)');
    const tabletQuery = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');

    const handleResize = () => checkDeviceType();
    
    mobileQuery.addListener(handleResize);
    tabletQuery.addListener(handleResize);

    return () => {
      mobileQuery.removeListener(handleResize);
      tabletQuery.removeListener(handleResize);
    };
  }, []);

  return deviceType;
};