import React, { useMemo, useState, useRef, useEffect } from 'react'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import { useIntl } from 'gatsby-plugin-intl-v6'
import { StaticImage } from 'gatsby-plugin-image'
import { communitiesZhList, communitiesEnList } from '../utils/communities'
import banner from '../static/images/community/banner.png'
import { useDeviceDetect } from '../utils/useDeviceDetect';
import { motion } from "framer-motion";

export default Community

function Community() {
  let pageModel = new PageModelTitle('Community', 'community-page')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()
  const socialMediaList = ['X', 'Telegram', 'Facebook', 'Instagram', 'Discord', 'Tiktok', 'Medium', 'Threads']

  const { isMobile } = useDeviceDetect();
  const navHeight = isMobile ? 70 : 140;

  const communitiesList = useMemo(() => {
    return intl.locale === 'zh' ? communitiesZhList : communitiesEnList
  }, [communitiesEnList, communitiesZhList])

  const [activeId, setActiveId] = useState('');
  const [isSticky, setIsSticky] = useState(false);
  const navRef = useRef(null);
  const sentinelRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      { threshold: [1] }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, []);

  const handleNavClick = (id:any) => {
    setActiveId(id);
    const navId = id + '-links'
    const element = document.getElementById(navId);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset - navHeight;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div className={'banner-pc container'}>
        <div className='content-box'>
          <motion.div 
            className={'banner-title'}
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            {intl.formatMessage({ id: 'AB Global Community.text'}, 
              {Community: <span>{intl.formatMessage({ id: 'AB Global Community.Community'})}</span> })}
          </motion.div>
          <motion.div 
            className={'banner-sub-title'}
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            {intl.formatMessage({ id: 'The AB Global Community is a vibrant and mission-driven global family, dedicated to advancing charitable causes through blockchain technology.' })}
          </motion.div>
        </div>
        <motion.div 
          className='img-box'
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <img src={banner} className='banner-img' />
        </motion.div>
      </div>
      <div className={'banner-mobile container'}>
        <motion.div 
          className={'banner-title'}
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {intl.formatMessage({ id: 'AB Global Community.text'}, 
            {Community: <span>{intl.formatMessage({ id: 'AB Global Community.Community'})}</span> })}
        </motion.div>
        <motion.div 
          className={'banner-sub-title'}
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {intl.formatMessage({ id: 'The AB Global Community is a vibrant and mission-driven global family, dedicated to advancing charitable causes through blockchain technology.' })}
        </motion.div>
        <motion.img 
          src={banner} 
          className='banner-img' 
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        />
      </div>

      <div className='subscription container'>
        <p>{intl.formatMessage({ id: 'Global Subscription' })}</p>
        <div className='subscription-box'>
          <a className='comm-item' href='https://x.com/ABCharityFund' target='_blank'>
            <div>
              <StaticImage
                alt="x"
                class='icon'
                src="../static/images/community/x.png"
              ></StaticImage>
              <span>X</span>
            </div>
            <StaticImage
                alt="x"
                class='icon-path'
                src="../static/images/community/path.png"
              ></StaticImage>
          </a>
          <a className='comm-item' href='https://t.me/ABCharityFund' target='_blank'>
            <div>
              <StaticImage
                alt="telegram"
                class='icon'
                src="../static/images/community/telegram.png"
              ></StaticImage>
              <span>Telegram</span>
            </div>
            <StaticImage
                alt="x"
                class='icon-path'
                src="../static/images/community/path.png"
              ></StaticImage>
          </a>
        </div>
      </div>

      <div className='community container'>
        <p>{intl.formatMessage({ id: 'Global AB Community' })}</p>
        <div ref={sentinelRef} style={{ height: '1px' }} />
        <div 
          className='social-medias' 
          ref={navRef}
          style={{backgroundColor: isSticky ? '#161616' : 'transparent'}}
        >
          {socialMediaList.map((item: string, index: number) => {
            const iconName = item.toLowerCase()
            const imagePath = require(`../static/images/community/${iconName}.png`).default;
            return (
            <div 
              key={index} 
              className={activeId === item ? 'media-item active' : 'media-item'} 
              onClick={() => handleNavClick(item)}
            >
              <div className='media-box'>
                <img src={imagePath} alt={iconName} className="icon" />
                <span>{item}</span>
              </div>
              <div className='bottom'></div>
            </div>
            )
          })}
        </div>

        <div 
          className='social-medias-h5' 
          ref={navRef}
          style={{backgroundColor: isSticky ? '#161616' : 'transparent'}}
        >
          <div className='medias-box'>
          {socialMediaList.map((item: string, index: number) => {
            return (
            <div 
              key={index} 
              className={activeId === item ? 'media-item active' : 'media-item'}
              onClick={() => handleNavClick(item)}
            >
              <span>{item}</span>
            </div>
            )
          })}
          </div>
          {/* <div className='button-box'>
            <StaticImage
              alt="path"
              class='icon-path'
              src="../static/images/community/path.png"
            ></StaticImage>
          </div> */}
        </div>

        <div className='links-box' id='X-links'>
          <div className='link-header'>
            <img src={require(`../static/images/community/x.png`).default} alt='x' className="icon" />
            <span>X</span>
          </div>
          <div className='links'>
          {communitiesList['X'].map((item: any, index: number) => {
            return (
              <a key={index} className={index % 4 == 0 ? 'link-item ml0' : 'link-item'} href={item.url} target='_blank'>
                <span>{item.text}</span>
                <StaticImage
                  alt="path"
                  class='icon-path'
                  src="../static/images/community/path.png"
                ></StaticImage>
              </a>
            )
          })}
          </div>
        </div>

        <div className='links-box' id='Telegram-links'>
          <div className='link-header'>
            <img src={require(`../static/images/community/telegram.png`).default} alt='telegram' className="icon" />
            <span>Telegram</span>
          </div>
          <div className='links'>
          {communitiesList['Telegram'].map((item: any, index: number) => {
            return (
              <a key={index} className={index % 4 == 0 ? 'link-item ml0' : 'link-item'} href={item.url} target='_blank'>
                <span>{item.text}</span>
                <StaticImage
                  alt="path"
                  class='icon-path'
                  src="../static/images/community/path.png"
                ></StaticImage>
              </a>
            )
          })}
          </div>
        </div>

        {/* <div className='links-box' id='Facebook-links'>
          <div className='link-header'>
            <img src={require(`../static/images/community/facebook.png`).default} alt='facebook' className="icon" />
            <span>Facebook</span>
          </div>
          <div className='links'>
          {communitiesList['Facebook'].map((item: any, index: number) => {
            return (
              <a key={index} className={index % 4 == 0 ? 'link-item ml0' : 'link-item'} href={item.url} target='_blank'>
                <span>{item.text}</span>
                <StaticImage
                  alt="path"
                  class='icon-path'
                  src="../static/images/community/path.png"
                ></StaticImage>
              </a>
            )
          })}
          </div>
        </div> */}

        <div className='links-box' id='Instagram-links'>
          <div className='link-header'>
            <img src={require(`../static/images/community/instagram.png`).default} alt='instagram' className="icon" />
            <span>Instagram</span>
          </div>
          <div className='links'>
          {communitiesList['Instagram'].map((item: any, index: number) => {
            return (
              <a key={index} className={index % 4 == 0 ? 'link-item ml0' : 'link-item'} href={item.url} target='_blank'>
                <span>{item.text}</span>
                <StaticImage
                  alt="path"
                  class='icon-path'
                  src="../static/images/community/path.png"
                ></StaticImage>
              </a>
            )
          })}
          </div>
        </div>

        <div className='links-box' id='Discord-links'>
          <div className='link-header'>
            <img src={require(`../static/images/community/discord.png`).default} alt='telegram' className="icon" />
            <span>Discord</span>
          </div>
          <div className='links'>
          {communitiesList['Discord'].map((item: any, index: number) => {
            return (
              <a key={index} className={index % 4 == 0 ? 'link-item ml0' : 'link-item'} href={item.url} target='_blank'>
                <span>{item.text}</span>
                <StaticImage
                  alt="path"
                  class='icon-path'
                  src="../static/images/community/path.png"
                ></StaticImage>
              </a>
            )
          })}
          </div>
        </div>

        <div className='links-box' id='Tiktok-links'>
          <div className='link-header'>
            <img src={require(`../static/images/community/tiktok.png`).default} alt='Tiktok' className="icon" />
            <span>Tiktok</span>
          </div>
          <div className='links'>
          {communitiesList['Tiktok'].map((item: any, index: number) => {
            return (
              <a key={index} className={index % 4 == 0 ? 'link-item ml0' : 'link-item'} href={item.url} target='_blank'>
                <span>{item.text}</span>
                <StaticImage
                  alt="path"
                  class='icon-path'
                  src="../static/images/community/path.png"
                ></StaticImage>
              </a>
            )
          })}
          </div>
        </div>

        <div className='links-box' id='Medium-links'>
          <div className='link-header'>
            <img src={require(`../static/images/community/medium.png`).default} alt='Medium' className="icon" />
            <span>Medium</span>
          </div>
          <div className='links'>
          {communitiesList['Medium'].map((item: any, index: number) => {
            return (
              <a key={index} className={index % 4 == 0 ? 'link-item ml0' : 'link-item'} href={item.url} target='_blank'>
                <span>{item.text}</span>
                <StaticImage
                  alt="path"
                  class='icon-path'
                  src="../static/images/community/path.png"
                ></StaticImage>
              </a>
            )
          })}
          </div>
        </div>

        <div className='links-box' id='Threads-links'>
          <div className='link-header'>
            <img src={require(`../static/images/community/threads.png`).default} alt='Threads' className="icon" />
            <span>Threads</span>
          </div>
          <div className='links'>
          {communitiesList['Threads'].map((item: any, index: number) => {
            return (
              <a key={index} className={index % 4 == 0 ? 'link-item ml0' : 'link-item'} href={item.url} target='_blank'>
                <span>{item.text}</span>
                <StaticImage
                  alt="path"
                  class='icon-path'
                  src="../static/images/community/path.png"
                ></StaticImage>
              </a>
            )
          })}
          </div>
        </div>

      </div>
    </div>
  )
}
