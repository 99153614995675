export const communitiesZhList = {
  'X': [
    {
      'text': '英语',
      'url': 'https://x.com/ABDAO_Global'
    },
    // {
    //   'text': '中文',
    //   'url': 'https://x.com/ABDAO_CN'
    // },
    {
      'text': '印尼',
      'url': 'https://x.com/ABDAO_Indonesia'
    },
    // {
    //   'text': '香港',
    //   'url': 'https://x.com/ab_daohk'
    // },
    {
      'text': '日本',
      'url': 'https://x.com/ab_daojapan'
    },
    {
      'text': '越南',
      'url': 'https://x.com/ab_vietnam'
    },
    
    // {
    //   'text': '台湾',
    //   'url': 'https://x.com/ab_daotaiwan'
    // },
  ],
  'Telegram': [
    {
      'text': '社区频道',
      'url': 'https://t.me/ABGlobalNews'
    },
    {
      'text': '英语',
      'url': 'https://t.me/Newtonproject'
    },
    {
      'text': '中文',
      'url': 'https://t.me/ABCNCommunity'
    },
    {
      'text': '泰国',
      'url': 'https://t.me/AB_TH_Official'
    },
    {
      'text': '菲律宾',
      'url': 'https://t.me/AB_PH_Official'
    },
    {
      'text': '越南',
      'url': 'https://t.me/abprojectvietnam'
    },
    {
      'text': '印尼',
      'url': 'https://t.me/abindonesiagrup'
    },
    {
      'text': '印尼订阅',
      'url': 'https://t.me/abindonesia'
    },
    {
      'text': '香港',
      'url': 'https://t.me/ABHKCommunity'
    },
    {
      'text': '日本',
      'url': 'https://t.me/ABJPCommunity'
    },
    {
      'text': '台湾',
      'url': 'https://t.me/ABTWCommunity'
    },
  ],
  // 'Facebook': [
  //   {
  //     'text': '印尼',
  //     'url': 'https://www.facebook.com/abvietnamnewton'
  //   }
  // ], 
  'Instagram': [
    {
      'text': '越南',
      'url': 'https://www.instagram.com/ab.vietnam'
    },
    {
      'text': '印尼',
      'url': 'https://www.instagram.com/abdao_indonesia/'
    }
  ], 
  'Discord': [
    {
      'text': '英语',
      'url': 'https://discord.gg/BSbgK6J'
    },
    {
      'text': '越南',
      'url': 'https://discord.com/invite/xwtZmJnqEM'
    }
  ], 
  'Tiktok': [
    {
      'text': '越南',
      'url': 'https://www.tiktok.com/@ab.vietnam'
    }
  ], 
  'Medium': [
    {
        'text': '英语',
        'url': 'https://medium.com/@AB.org'
      }
  ], 
  'Threads': [
    {
        'text': '越南',
        'url': 'https://www.threads.net/@ab.vietnam'
      }
  ]
}

export const communitiesEnList = {
    'X': [
        {
          'text': 'English',
          'url': 'https://x.com/ABDAO_Global'
        },
      // {
      //   'text': '中文',
      //   'url': 'https://x.com/ABDAO_CN'
      // },
      {
        'text': 'Bahasa Indonesia',
        'url': 'https://x.com/ABDAO_Indonesia'
      },
      // {
      //   'text': 'HongKong',
      //   'url': 'https://x.com/ab_daohk'
      // },
      {
        'text': '日本語',
        'url': 'https://x.com/ab_daojapan'
      },
      {
        'text': 'Tiếng Việt',
        'url': 'https://x.com/ab_vietnam'
      },
      // {
      //   'text': 'Taiwan',
      //   'url': 'https://x.com/ab_daotaiwan'
      // },
    ],
    'Telegram': [
      {
        'text': 'Channel',
        'url': 'https://t.me/ABGlobalNews'
      },
      {
        'text': 'English',
        'url': 'https://t.me/Newtonproject'
      },
      {
        'text': '中文',
        'url': 'https://t.me/ABCNCommunity'
      },
      {
        'text': 'ประเทศไทย',
        'url': 'https://t.me/AB_TH_Official'
      },
      {
        'text': 'Filipino',
        'url': 'https://t.me/AB_PH_Official'
      },
      {
        'text': 'Tiếng Việt',
        'url': 'https://t.me/abprojectvietnam'
      },
      {
        'text': 'Bahasa Indonesia',
        'url': 'https://t.me/abindonesiagrup'
      },
      {
        'text': 'Langganan Indonesia',
        'url': 'https://t.me/abindonesia'
      },
      {
        'text': 'HongKong',
        'url': 'https://t.me/ABHKCommunity'
      },
      {
        'text': '日本語',
        'url': 'https://t.me/ABJPCommunity'
      },
      {
        'text': '台灣',
        'url': 'https://t.me/ABTWCommunity'
      },
    ],
    // 'Facebook': [
    //   {
    //     'text': 'Bahasa Indonesia',
    //     'url': 'https://www.facebook.com/abvietnamnewton'
    //   }
    // ], 
    'Instagram': [
      {
        'text': 'Tiếng Việt',
        'url': 'https://www.instagram.com/ab.vietnam'
      },
      {
        'text': 'Bahasa Indonesia',
        'url': 'https://www.instagram.com/abdao_indonesia/'
      }
    ], 
    'Discord': [
      {
        'text': 'English',
        'url': 'https://discord.gg/BSbgK6J'
      },
      {
        'text': 'Tiếng Việt',
        'url': 'https://discord.com/invite/xwtZmJnqEM'
      }
    ], 
    'Tiktok': [
      {
        'text': 'Tiếng Việt',
        'url': 'https://www.tiktok.com/@ab.vietnam'
      }
    ], 
    'Medium': [
      {
          'text': 'English',
          'url': 'https://medium.com/@AB.org'
        }
    ], 
    'Threads': [
      {
          'text': 'Tiếng Việt',
          'url': 'https://www.threads.net/@ab.vietnam'
        }
    ]
}